// import jQuery from 'jquery'

import AOS from "aos";
import "aos/dist/aos.css";
import Rellax from "rellax";
import Flickity from "flickity";

AOS.init({
  once: true,
});

var rellax = new Rellax(".bg_element_parallax", {
  speed: 3,
  center: true,
  wrapper: null,
  round: true,
  vertical: true,
  horizontal: false,
});

var carouselGallery = new Flickity(".carousel-gallery", {
  lazyLoad: 2,
  prevNextButtons: false,
  pageDots: true,
  wrapAround: true,
  autoPlay: true,
});

var carouselAree;

calcCarouel();

window.addEventListener("resize", calcCarouel);

function calcCarouel() {
  if (matchMedia("screen and (max-width: 800px)").matches) {
    carouselAree = new Flickity(".carousel-aree", {
      prevNextButtons: false,
      pageDots: false,
      cellAlign: "left",
    });
  } else {
    if (carouselAree) carouselAree.destroy();
  }
}

// (function( root, $, undefined ) {
// 	"use strict";
//
// 	$(function () {
//
//
// 	});
//
//
// } ( this, jQuery ));
